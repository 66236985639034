export default function select() {
  $('.resource-select').select2({
    // containerCssClass: 'resource-select-container',
    dropdownCssClass: 'resource-select-dropdown',
    // selectionCssClass: 'resource-select-selection',
  });
  $('.homepage-select').select2({
    containerCssClass: 'homepage-select-container',
    dropdownCssClass: 'homepage-select-dropdown',
    selectionCssClass: 'homepage-select-selection',
  });

  let isIE = false;
  const ua = window.navigator.userAgent;
  const oldIE = ua.indexOf('MSIE ');
  const newIE = ua.indexOf('Trident/');

  if (oldIE > -1 || newIE > -1) {
    isIE = true;
  }

  if (isIE) {
    $('.block__dot-entry').addClass('is-ie');
    $('.footer').addClass('is-ie');
  }
}
